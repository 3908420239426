import React, { useEffect, useState, useRef } from 'react';

// Helper function to convert milliseconds to a readable format
function convertMilliseconds(ms) {
    const millisecondsInSecond = 1000;
    const secondsInMinute = 60;
    const minutesInHour = 60;
    const hoursInDay = 24;
    const daysInYear = 365; // Approximation
    const daysInMonth = 30; // Approximation

    const now = Date.now();
    const pastTime = now - ms;

    const years = Math.floor(pastTime / (millisecondsInSecond * secondsInMinute * minutesInHour * hoursInDay * daysInYear));
    const months = Math.floor(pastTime / (millisecondsInSecond * secondsInMinute * minutesInHour * hoursInDay * daysInMonth));
    const days = Math.floor(pastTime / (millisecondsInSecond * secondsInMinute * minutesInHour * hoursInDay));
    
    if (years > 0) {
        return years + (years === 1 ? " year ago" : " years ago");
    } else if (months > 0) {
        return months + (months === 1 ? " month ago" : " months ago");
    } else if (days > 0) {
        return days + (days === 1 ? " day ago" : " days ago");
    } else {
        return "today";
    }
}

// Chip component to display individual tags
const Chip = ({ label }) => (
  <span className="chip">{label}</span>
);

const JobList = (props) => {
  const [jobs, setJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true); // New boolean state variable
  const [companyLogoMap, setCompanyLogoMap] = useState({});
  const lastFetchTime = useRef(0);

  useEffect(() => {
    // Fetch jobs from MongoDB (replace with your API endpoint)
    const now = Date.now();
    if (now - lastFetchTime.current > 60000) {
      fetch('https://enclave-wallet-backend-dev-wgltufiroq-el.a.run.app/api/jobs')
        .then(response => response.json())
        .then(data => {
          setAllJobs(data)
          const logoMap = {};
          data.forEach(job => {
            if (job.company_name && job.company_url) {
              console.log(job.company_name, job.company_url);
              logoMap[job.company_name] = job.company_url;
            }
          });
          setCompanyLogoMap(logoMap);
          let filteredJobs;
          if (firstLoad === false) {
            filteredJobs = allJobs.filter(job =>
              job.title.toLowerCase().includes(props.searchTerm.toLowerCase()) ||
              job.company_name.toLowerCase().includes(props.searchTerm.toLowerCase()) ||
              (job.skill_tags && job.skill_tags.some(tag => 
                tag.toLowerCase().includes(props.searchTerm.toLowerCase())
              ))
            );
          }
          else {
            filteredJobs = data;
            setFirstLoad(false);
          }
          setJobs(filteredJobs);
          lastFetchTime.current = now;
        })
        .catch(error => console.error('Error fetching jobs:', error));
    }
    else {
      const filteredJobs = allJobs.filter(job =>
        job.title.toLowerCase().includes(props.searchTerm.toLowerCase()) ||
        job.company_name.toLowerCase().includes(props.searchTerm.toLowerCase()) ||
        (job.skill_tags && job.skill_tags.some(tag => 
          tag.toLowerCase().includes(props.searchTerm.toLowerCase())
        ))
      );
      setJobs(filteredJobs);
    }
  }, [props.searchTerm])

  return (
    <div className="job-list-container">
      <h2 className="job-listings-title">Job Listings ({jobs.length})</h2>
      <div className="job-list">
        {jobs.map(job => (
          <div key={job._id} className="job-row">
            <a href={job.url} target="_blank" rel="noopener noreferrer" className="job-card-link">
              <div className="job-card">
                <div className="company-icon">
                  {companyLogoMap[job.company_name] ? (
                    <img 
                      src={companyLogoMap[job.company_name]}  
                      alt={job.company_name}
                      onError={(e) => {
                        e.target.onerror = null; // Prevents infinite loop if fallback also fails
                        e.target.src = '/path/to/fallback-image.png'; // Replace with path to a default image
                      }}
                    />
                  ) : (
                    <div className="fallback-icon">{job.company_name[0]}</div>
                  )}
                </div>
                <div className="job-details">
                  <h3>{job.title}</h3>
                  <h4>{job.company_name}</h4>
                  <p>
                    Posted: {convertMilliseconds(job.time_posted * 1000)}
                    {job.scraper_start_time && (
                      <span style={{ marginLeft: '5px' }}> {/* Reduced margin */}
                      . Refreshed: {convertMilliseconds(job.scraper_start_time)}
                      </span>
                    )}
                  </p>
                  <div className="company-tags">
                    {job.fund && <Chip key="fund" label={job.fund} />}
                    {
                    job.skill_tags && job.skill_tags.map((tag, index) => (
                      <Chip key={index} label={tag} />
                    ))}
                  </div>
                </div>
                <div className="apply-button-container">
                  <span className="apply-button">Apply Now</span>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobList;