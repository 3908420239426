import React, { useState } from 'react';
import './SearchBar.css';

function Chip({ label, onClick }) {
  return (
    <span className="chip" onClick={() => onClick(label)}>
      {label}
    </span>
  );
}

function SearchBar({ setSearchTerm }) {
  const [searchText, setSearchText] = useState('');
  const suggestedQueries = ['backend', , 'frontend', 'fullstack', 'blockchain', 'data', 'product', 'design', 'marketing', 'intern', 'seed'];

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
    setSearchTerm(value);
  };

  const handleChipClick = (query) => {
    setSearchText(query);
    setSearchTerm(query);
  };

  return (
    <div className="search-bar">
      <h2 className="search-bar-title">Search</h2>
      <input
        type="text"
        value={searchText}
        placeholder="Search by company name, job title or tag"
        onChange={handleSearch}
      />
      <div className="suggested-queries">
        {suggestedQueries.map((query, index) => (
          <Chip key={index} label={query} onClick={handleChipClick} />
        ))}
      </div>
    </div>
  );
}

export default SearchBar;