// src/components/Header.js
import React from 'react';
import logo from '../assets/lll.png';
import { Link } from '@mui/material';
import WebIcon from '@mui/icons-material/Web';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import './Header.css'; // Ensure you have a CSS file for styling

const Header = () => {
  return (
    <header className="header">
      <img src={logo} alt="Header" className="header-logo" />
      <nav className="header-nav">
        <ul>
          <li>
            <Link href="https://enclave.money" target="_blank" rel="noopener noreferrer" className="nav-link">
              <WebIcon className="nav-icon" />
            </Link>
          </li>
          <li>
            <Link href="https://t.me/enclavemoney" target="_blank" rel="noopener noreferrer" className="nav-link">
              <TelegramIcon className="nav-icon" />
            </Link>
          </li>
          <li>
            <Link href="https://x.com/getenclavemoney" target="_blank" rel="noopener noreferrer" className="nav-link">
              <TwitterIcon className="nav-icon" />
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;