import { useState } from 'react';
import React from 'react';
import './App.css';
import Header from './components/Header';
import JobFilters from './components/JobFilters';
import JobList from './components/JobList';
import SearchBar from './components/SearchBar';

function App() {

  const [func, setFunc] = useState('')
  const [seniority, setSeniority] = useState('')
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="App">
      <Header />
      <SearchBar setSearchTerm={setSearchTerm} />
      {/* <JobFilters setFunc={setFunc} setSeniority={setSeniority} /> */}
      <JobList searchTerm={searchTerm}/>
    </div>
  );
}

export default App;